.wrap {
  font-family: var(--Montserrat);
  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  color:var(--color-text);
  background: linear-gradient(119deg, #FFF7F5 5.03%, #FFF9F8 5.04%, #FFF3FE 53.09%, #F3FEFF 81.78%);
  position: relative;
}

.wrap__main {
  //overflow: hidden;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  padding: 0 5rem;
}


.header{
  position: fixed;
  width: 100%;
  z-index: 4;
  .container{
    padding:1.6rem 5rem;
  }
  &.scroll{
    border-bottom: 0.1rem solid var(--color-pink);
    background-color: var(--color-white);
    //background: linear-gradient(119deg, #FFF7F5 5.03%, #FFF9F8 5.04%, #FFF3FE 88%);
  }
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    width: 13.9rem;
    display: block;
  }
  &__contact{
    font: 600 1.6rem / normal var(--Montserrat);
    display: block;
    color:var(--color-black);
    transition: 0.2s all ease;
    &:hover{
      transition: 0.2s all ease;
      background: var(--color-text-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.banner{
  &__text{
    font: 700 13.3rem / normal var(--MM);
    text-align: center;
  }
}

.main{
  &__section{
    min-height: 100vh;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    //position: absolute;
    //top: 0;
    //left: 0;
    ////color:white;
    //visibility: hidden;
    //-webkit-transform: translateZ(0);
    //transform: translateZ(0);
    //&.active {
    //  visibility: visible;
    //}
    .container{
      padding-top: 7rem ;
      padding-bottom: 7rem;
    }
  }
  &__text{
    &-gradient{
      background: var(--color-text-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}


.policy{
  .container{
    padding: 11rem 10rem;
  }
  &__title{
    text-align: center;
    font: 700 8rem / 9rem var(--MM);
    margin-bottom: 6rem;
  }
  &__block{
    display: flex;
    justify-content: space-between;
    gap:1.6rem;
    align-items: flex-start;
    max-width: 105.3rem;
    margin: 0 auto;
  }
  &__list{
    display: flex;
    gap:1.6rem;
    flex-direction: column;
    width: 71%;
    position: relative;
  }
  &__item{
    border-radius: 2rem;
    padding:4rem;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    gap:1.6rem;
    h2{
      color:var(--color-violet);
      font: 700 4rem / 100% var(--MM);
      margin-bottom: 0.8rem;
    }
    p{
      color: var(--color-text);
      font: 400 1.6rem / 150% var(--Montserrat);
    }
    a{
      font: 400 1.6rem / 150% var(--Montserrat);
      text-decoration: underline;
    }
    li{
      font: 400 1.6rem / 150% var(--Montserrat);
      padding-left: 2.5rem;
      position: relative;
      color: var(--color-text);
      &:after{
        content:'';
        position: absolute;
        width: 0.3rem;
        height: 0.3rem;
        background-color: var(--color-text);
        border-radius: 50%;
        top:50%;
        left: 1rem;
        transform: translateY(-50%);

      }
    }
    ul{
      display: flex;
      flex-direction: column;
      gap:0;
    }

  }
  &__anchor{
    border-radius: 2rem;
    padding:4rem 2.4rem;
    background-color: white;
    width: calc(29% - 1.6rem);
    position: sticky;
    top:7rem;

    ul{
      display: flex;
      flex-direction: column;
      gap:2.4rem;

      a{
        font: 400 1.6rem / 1.6rem var(--MM);
        color: #566277;
        &.active{
          background: var(--color-text-gradient);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.contact{
  .container{
    padding: 18.8rem 5rem 5rem;
  }
  &__block{
    display: flex;
   justify-content: space-between;
  }
  &__text{
    font: 700 8rem/9rem var(--MM) ;
    color:var(--color-violet);
  }

  &__form{
    display: flex;
    flex-direction: column;
    gap:2.4rem;
    &-wrap{
      max-width: 35.6rem;
      width: 100%;
      margin-top: 8rem;
      margin-right: 16rem;
    }
    &.hide{
      display: none;
    }
    h2{
      font: 700 2.4rem / 2.4rem var(--MM);
      margin-bottom: 1.6rem;
    }
    p{
      color:var(--color-contact-grey);
      font: 400 1.6rem / 1.6rem var(--Montserrat);
    }
    &-message{
      display: none;
      flex-direction: column;
      gap:2rem;
      border-radius: 2rem;
      padding:4rem 2.4rem;
      background-color: white;
      min-height: 30.8rem;
      justify-content: center;
      & > *{
        text-align: center;
        margin: 0 auto;
      }
      p{
        font: 400 1.6rem / 1.6rem var(--Montserrat);
        color:var(--color-contact-grey);
      }
      .contact__button{
        margin: 0 auto;
      }
      &.show{
        display: flex;
      }
    }
  }
  &__button{
    padding: 1.6rem 7.2rem;
    color:white;
    border-radius: 1rem;
    font: 700 1.6rem / 1.6rem var(--Montserrat);
    width: max-content;
    background:linear-gradient(#FFA68F, #FFB2FB, #D9A9FF);
    margin-top: 1.6rem;
    transition: 0.2s all ease;
    &:hover{
      box-shadow: 0 0 2rem 0 #FFB2FB;
      transition: 0.2s all ease;
    }
  }
  &__input{
    display: flex;
    gap:1.8rem;
    position: relative;
    flex-direction: column;

    label{
      font: 600 1.2rem / 1.2rem var(--Montserrat);
    }
    input{
      font: 400 1.6rem / 1.6rem var(--Montserrat);
      border: none;
      background: transparent;
      padding-bottom: 1.2rem;
      border-bottom: 0.1rem solid var(--color-contact-grey);
      color:var(--color-violet);
      &::placeholder{
        color:var(--color-contact-grey);
      }
    }
  }
  label.error{
    color:#FE4A4A;
    position: absolute;
    bottom: -1.4rem;
    font: 400 1.2rem / 1.2rem var(--Montserrat);
  }
}
.footer{
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  background-color: var(--color-white);
  border-top: 0.1rem solid var(--color-pink) ;
  * {
    font: 500 1.6rem / normal var(--ESE);
    color:var(--color-black);
  }
  .container{
    padding: 1.6rem 5rem;
  }
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__link{
    display: flex;
    gap:2rem;
    & > *:hover{
      transition: 0.2s all ease;
      background: var(--color-text-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__right{
    span{
      text-decoration: underline;
    }
    span:first-child{
      color: var(--color-green);

    }
  }
}
.phone{
  &__flex{
    display: flex;
    align-items: center;
    gap:5rem;
    justify-content: space-between;
  }
  &__title{
    font: 700 8rem / 112.5% var(--MM);
    color:var(--color-violet);
    margin-bottom: 3.2rem;
  }
  &__subtitle{
    font: 400 1.6rem / 2.4rem var(--Montserrat);
    max-width: 63rem;
  }
  &__button{
    display: flex;
    gap: 0.8rem;
    position: relative;
    width: max-content;
    margin-top: 9.5rem;
    & > a{
      display: block;
      height: 5.9rem;
    }
    &-img{
      position: absolute;
      top: -6rem;
      right: -8rem;
      width: 12.5rem;
    }
  }
  &__item{
    width: 50%;
    &-img{
      position: relative;
      margin-top: 10rem;
    }
    &-flex{
      justify-content: center;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
    }
    &-screen{
      border-radius: 3.5rem;

      &:first-child{
        width:37% ;
        margin-right: -9.5rem;
        margin-top: 5rem;
      }
      &:last-child{
        width:49% ;
        box-shadow: -1.6rem 0.9rem 4.84rem 0 rgba(12, 12, 42, 0.31);

      }
    }
  }
  &__icon{
    position: absolute;
    display: flex;
    align-items: center;
    gap:0.8rem;
    padding: 1.6rem;
    background-color: var(--color-white);
    border-radius: 1.6rem;
    box-shadow: -0.7rem 0.6rem 1rem 0 rgba(45, 8, 75, 0.15);
    z-index: 3;
    &:nth-child(1){
      top: 1rem;
      left: 4rem;
    }
    &:nth-child(2){
      top:13rem;
      right: -3rem;
    }
    &:nth-child(3){
      bottom: 11rem;
      left: -5rem;
    }
    &:nth-child(4){
      bottom:-3rem;
      right: -11rem;

    }
    &-img{
      //width: 4.8rem;
      height: 4.8rem;
    }
    h3{
      font: 700 1.6rem / 2.4rem var(--Montserrat);
      color: #000;
    }
  }
  &__back{
    position: absolute;
    z-index: 1;
    //width: 83.5rem;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &__reverse{
    .phone__flex{
      flex-direction: row-reverse;
    }
  }
}

.team{
  .container{
    position: relative;
  }
  &__back{
    position: absolute;
    width: 30%;
    z-index: -1;
    &:first-child{
      bottom: 7rem;
      left: 10rem;
    }
    &:last-child{
      bottom: 6rem;
      right: 15rem;
    }
  }
  &__text{
    font: 700 13.3rem / 9rem var(--MM);
    text-align: center;
    color:var(--color-violet);
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }
    &-subtext{
      position: relative;
      width: fit-content;
      margin: 0 auto;
    }
    &-img{
      position: absolute;
      right: -5rem;
      top: -7rem;
      width: 15.4rem;
    }
  }
  &__subtitle{
    margin: 4.8rem auto 7.2rem;
    text-align: center;
    max-width: 80.2rem;
    & > * {
      font: 400 1.6rem / 2.4rem var(--Montserrat);
    }
  }

}
.gallery {
  margin: 5rem 0 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1.4rem;
}

.gallery__item{
  border-radius: 3.5rem;
}
.gallery__item img{
  border-radius: 3.5rem;
}

.gallery__item:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery__item:nth-child(3) {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.gallery__item:nth-child(4) {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}
@media only screen and (max-width: 1630px) {
  .phone__icon:nth-child(4) {
    bottom: -5rem;
    right: -3rem;
  }
  .phone__icon:nth-child(3) {
    bottom: 6rem;
    left: -3rem;
  }
  .team__text{
    font-size: 8rem;
  }
  .team__subtitle{
    margin: 2rem auto 3rem;
  }
  .gallery{
    width:80% ;
    margin: 3rem auto 2rem ;
  }
  .team__text-wrap{
    gap:0;
  }

}
@media only screen and (max-width: 1440px) {
  .phone__item-screen:last-child{
    width: 43% ;
  }
  .phone__item-screen:first-child{
    width: 30% ;
  }

}

@media only screen and (max-width: 1360px) {

  .contact__form-wrap{
    margin-right: 5rem;
  }

}
@media only screen and (max-width: 1024px) {

  .gallery{
    width:100% ;
  }

  .main__section{
    min-height:auto;
  }
  .main__section .container{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

}




@media only screen and (max-width: 768px) {
  .phone__flex{
    flex-direction: column;
    gap:6rem;
    & > *{
      width: 100%;
      align-items: center;
    }
  }
  .phone__item-img{
    width: 80%;
    margin: 5rem auto 0 ;
  }
  .phone__reverse .phone__flex{
    flex-direction: column;
  }
}
@media only screen and (max-width: 666px) {
  .wrap .container{
    padding: 2rem ;
  }
  .contact__block{
    flex-direction: column;
    gap:4rem;
    & > * {
      width: 100%;
    }
  }
  .contact__form-wrap{
    margin: 0;
  }
  .contact__text{
    font-size: 4.6rem;
  }
  .policy__block{
    flex-direction: column-reverse;
    & > *{
      width: 100%;
    }
  }
  .policy .container{
    padding: 10rem 2rem 4rem;
  }
  .policy__anchor{
    position: relative;
    top:unset;
  }
  .policy__item h2{
    font-size: 2.8rem;
  }
  .policy__item{
    padding: 3.2rem 2.4rem;
  }
  .policy__anchor ul{
    gap:2rem;
  }
  .policy__item{
    gap:1rem;
  }
  .policy__title{
    font-size: 4.6rem;
    margin-bottom: 3rem;
    line-height: 120%;
  }
  .footer__block{
    flex-direction: column;
    gap:1rem
  }
  .footer__right{
    text-align: center;
    & > *{
      line-height: 150%;
    }
  }
  .footer{
   position: relative;
  }
  .contact .container{
    padding: 10rem 2rem 4rem;
  }
  .contact__form{
    gap:2rem;
  }
  .banner__text{
    font-size: 6rem;
  }
  .phone__title{
    font-size: 4.6rem;
  }
  .phone__button{
    margin-top: 5rem;
    width: 100%;
  }
  .phone__button > a{
    height: auto;
    width: calc(50% - 0.4rem);
  }
  .phone__button-img{
    width: 10rem;
    top: -5rem;
    right: -2rem;
  }
  .phone__icon h3{
    font-size: 1.4rem;
  }
  .phone__icon{
    padding: 0.6rem;
  }
  .phone__icon-img{
    height: 3.2rem;
    //width: 3.2rem;
    //min-width: 3.2rem;
  }
  .phone__item-screen:first-child{
    margin-right: -2rem;
    margin-top: 0;
  }
  .phone__icon:nth-child(1) {
    top: -2rem;
    left: 0rem;
  }
  .phone__icon:nth-child(2) {
    top: 3rem;
    right: -3rem;
  }
  .phone__icon:nth-child(3) {
    bottom: 1rem;
    left: -3rem;
  }
  .phone__item-img {
    width: 90%;
  }
  .team__text{
    font-size: 4.6rem;
    line-height: 120%;
  }
  .team__text-img{
    width: 7rem;
    right: -3rem;
    top: -2rem;
  }
  .gallery{
    width: 100%;
    display: flex;
    gap:1rem;
    flex-wrap: wrap;
    & > * {
      aspect-ratio: 1.1/1;
      width: calc(50% - 0.5rem);
    }
  }
  .team .container{
    padding: 12rem 2rem 2rem;
  }
  .phone .container{
    padding: 5rem 2rem 5rem;
  }
  .main__section{
    overflow: visible;
  }
  .main__section .container{
    padding: 10rem 2rem;
  }
  .banner .container{
    padding: 15rem 2rem 2rem;
  }
}